/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import Cta from '../sections/homepage/cta';

const Privacy = () => {
  return (
    <Layout title="Privacy Policy - ScrapeOwl">
      <div sx={styles.container}>
        <div sx={styles.content}>
          <h1>Privacy Policy</h1>

          <p>
            ScrapeOwl! (a.k.a. "we" or "us" or the "Company") values its
            visitors’ privacy. This privacy policy is effective 01/01/2020]; it
            summarizes what information we might collect from a registered user
            or other visitor (“you”), and what we will and will not do with it.
          </p>

          <p>
            Please note that this privacy policy does not govern the collection
            and use of information by companies that ScrapeOwl does not control,
            nor by individuals not employed or managed by ScrapeOwl. If you
            visit a Web site that we mention or link to, be sure to review its
            privacy policy before providing the site with information.
          </p>

          <h2>What we do with your personally identifiable information</h2>

          <p>
            It is always up to you whether to disclose personally identifiable
            information to us, although if you elect not to do so, we reserve
            the right not to register you as a user or provide you with any
            products or services. “Personally identifiable information” means
            information that can be used to identify you as an individual, such
            as, for example:
          </p>

          <ul>
            <li>
              your name, company, email address, phone number, billing address,
              and shipping address
            </li>

            <li>your ScrapeOwl user ID and password</li>

            <li>credit card information</li>

            <li>any account-preference information you provide us</li>

            <li>
              your computer’s domain name and IP address, indicating where your
              computer is located on the Internet
            </li>

            <li>
              session data for your login session, so that our computer can
              ‘talk’ to yours while you are logged in
            </li>
          </ul>

          <p>
            If you do provide personally identifiable information to us, either
            directly or through a reseller or other business partner, we will:
          </p>

          <ul>
            <li>
              not sell or rent it to a third party without your permission —
              although unless you opt out (see below), we may use your contact
              information to provide you with information we believe you need to
              know or may find useful, such as (for example) news about our
              services and products and modifications to the Terms of Service;
            </li>

            <li>
              take commercially reasonable precautions to protect the
              information from loss, misuse and unauthorized access, disclosure,
              alteration and destruction;
            </li>

            <li>
              <p>not use or disclose the information except:</p>

              <ul>
                <li>
                  as necessary to provide services or products you have ordered,
                  such as (for example) by providing it to a carrier to deliver
                  products you have ordered;
                </li>
                <li>
                  in other ways described in this privacy policy or to which you
                  have otherwise consented;
                </li>
                <li>
                  in the aggregate with other information in such a way so that
                  your identity cannot reasonably be determined (for example,
                  statistical compilations);
                </li>
                <li>
                  as required by law, for example, in response to a subpoena or
                  search warrant;
                </li>
                <li>
                  to outside auditors who have agreed to keep the information
                  confidential;
                </li>
                <li>as necessary to enforce the Terms of Service;</li>
                <li>
                  as necessary to protect the rights, safety, or property of
                  ScrapeOwl, its users, or others; this may include (for
                  example) exchanging information with other organizations for
                  fraud protection and/or risk reduction.
                </li>
              </ul>
            </li>
          </ul>

          <h2>Other information we collect</h2>
          <p>
            We may collect other information that cannot be readily used to
            identify you, such as (for example) the domain name and IP address
            of your computer. We may use this information, individually or in
            the aggregate, for technical administration of our Web site(s);
            research and development; customer- and account administration; and
            to help us focus our marketing efforts more precisely.
          </p>

          <h2>Cookies</h2>
          <p>
            ScrapeOwl uses “cookies” to store personal data on your computer. We
            may also link information stored on your computer in cookies with
            personal data about specific individuals stored on our servers. If
            you set up your Web browser (for example, Internet Explorer or
            Firefox) so that cookies are not allowed, you might not be able to
            use some or all of the features of our Web site(s).
          </p>

          <h2>External data storage sites</h2>
          <p>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </p>

          <h2>Your privacy responsibilities</h2>
          <p>To help protect your privacy, be sure:</p>
          <ul>
            <li>not to share your user ID or password with anyone else;</li>
            <li>to log off the ScrapeOwl Web site when you are finished;</li>
            <li>
              to take customary precautions to guard against “malware” (viruses,
              Trojan horses, bots, etc.), for example by installing and updating
              suitable anti-virus software.
            </li>
          </ul>

          <h2>Notice to European Union users</h2>
          <p>
            ScrapeOwl‘s operations are located primarily in the United States.
            If you provide information to us, the information will be
            transferred out of the European Union (EU) to the United States. By
            providing personal information to us, you are consenting to its
            storage and use as described herein.
          </p>

          <h2>Information collected from children</h2>
          <p>
            You must be at least 13 years old to use ScrapeOwl‘s Web site(s) and
            service(s). ScrapeOwl does not knowingly collect information from
            children under 13. (See the [U.S.] Children’s Online Privacy
            Protection Act.)
          </p>

          <h2>Changes to this privacy policy</h2>
          <p>
            We reserve the right to change this privacy policy as we deem
            necessary or appropriate because of legal compliance requirements or
            changes in our business practices. If you have provided us with an
            email address, we will endeavor to notify you, by email to that
            address, of any material change to how we will use personally
            identifiable information.
          </p>

          <h2>Questions or comments?</h2>
          <p>
            If you have questions or comments about ScrapeOwl's privacy policy,
            send an email to{' '}
            <a href="mailto:support@scrapeowl.com">support@scrapeowl.com</a>
          </p>
        </div>

        <Cta />
      </div>
    </Layout>
  );
};

export default Privacy;

const styles = {
  container: {
    variant: 'layout.container',
  },

  content: {
    my: 6,
    maxWidth: '80ch',
    wordWrap: 'break-word',
    color: 'rgb(55, 41, 44)',
    mx: 'auto',

    '& > * + *': {
      mt: 2,
    },

    h1: {
      fontSize: [3, 4, 5],
      textAlign: 'center',
      mb: 3,
    },

    h2: {
      fontSize: 4,
    },

    h3: {
      fontSize: [3],
    },

    'h1, h2, h3': {
      color: 'purplegray',
      fontWeight: 'bold',
    },

    'h1 + p, h2 + p, h3 + p': {
      mt: 1,
    },

    '* + h1, * + h2, * + h3': {
      mt: [4, 5],
    },

    p: {
      fontSize: '1rem',
      mt: 4,
    },

    ol: {
      pl: '1rem',
      mt: 4,
    },

    ul: {
      pl: '1rem',
      mt: 4,
    },

    li: {
      listStyleType: 'disc',
    },

    '& li + li': {
      mt: 2,
    },

    a: {
      color: 'purple',
      fontWeight: 'bold',
    },

    'a:hover': {
      textDecoration: 'underline',
    },
  },
};
